.switch {
    position: relative;
    display: inline-block;
    margin: 0;
}

.switch>span {
    position: absolute;
    top: 6px;
    pointer-events: none;
    font-family: 'Helvetica', Arial, sans-serif;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    width: 1%;
    text-align: center;
}

input.check-toggle-round-flat:checked~.off {
    color: #f66b0c;
}

input.check-toggle-round-flat:checked~.on {
    color: #fff;
}

.switch>span.on {
    left: 0;
    padding-left: 8px;
    color: #f66b0c;
}

.switch>span.off {
    right: 0;
    padding-right: 162px;
    color: #fff;
}

.check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.check-toggle+label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input.check-toggle-round-flat+label {
    padding: 2px;
    width: 60px;
    height: 25px;
    background-color: #f66b0c;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
}

input.check-toggle-round-flat+label:before,
input.check-toggle-round-flat+label:after {
    display: block;
    position: absolute;
    content: "";
}

input.check-toggle-round-flat+label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #f66b0c;
    -webkit--moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
}

input.check-toggle-round-flat+label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 22px;
    background-color: #fff;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
}

input.check-toggle-round-flat:checked+label:after {
    margin-left: 29px;
}