* {
    
    margin: 0;
    padding: 0;
}

#brand {
    margin-left: 50px;
    margin-right: auto;
    padding-top: 0;
}

#brand img {
    width: 140px;
}

.mynav {
    display: flex;
    justify-content: space-between;
    z-index: 10;
    height: 70px;
    background-color: transparent;
    transition: all .2s ease-in-out;
}

@media screen and (max-width:768px){
    .mynav {
        background-color: transparent;
    }
}

.mynav.active {
    background-color: #ffff;
    -moz-box-shadow: 0px 3px 16px -4px rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0px 3px 16px -4px rgba(0, 0, 0, 0.19);
    box-shadow: 0px 3px 16px -4px rgba(0, 0, 0, 0.19);
}

@media screen and (max-width:768px){
    .mynav.active {
        background-color: #ffff;
    
    }
}

#nav {
    display: flex;
    align-items: center;
    margin-right: 60px;
}

#link {
    margin-right: 20px;
    position: relative;
    color: black;
    font-weight: 500;
}

#linkicon {
    margin-right: 20px;
    position: relative;
    color: black;
    font-weight: 500;
}

#link::after {
    position: absolute;
    content: "";
    width: 0%;
    height: 2px;
    background-color: #f66b0c;
    left: 50%;
    bottom: 5px;
    transition: all 0.4s ease-in-out;
}

#link:hover,
#link:focus {
    color: #f66b0c;
}

#link:hover::after,
#link:focus::after {
    width: 100%;
    left: 0;
}

#signin {
    height: 36px;
    background-color: #f66b0c;
    color: #ffff;
    font-weight: 300;
    margin-right: 20px;
}

#signin:hover {
    color: rgb(243, 243, 243);
    background-color: black;
    transition: all 0.36s ease-in-out;
}

#collapse {
    flex-grow: 0;
}

@media screen and (max-width:768px) {
    #collapse {
        background-color: #ffff;
        padding-top: 6vh;
        margin-top: -6vh;
        padding-bottom: 2vh;
    }
    #nav {
        margin-right: 10px;
    }
    .content {
        margin-top: -60%;
    }
    #brand {
        margin-left: 5px;
    }
}

#navtoggle,
#navtoggle:active,
#navtoggle:focus {
    outline: none;
    border: 0;
    box-shadow: none;
}

.togglebar {
    background-color: #f66b0c;
    height: 4px;
    width: 30px;
    display: block;
    border-radius: 6px;
    transition: all 0.42s ease-in-out;
}

.middle-bar {
    margin: 5px 0px 5px 10px;
    width: 20px;
    transition: all 0.2s ease-in-out;
}

#myVideo {
    margin-top: -80px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    text-align: center;
    color: white;
    position: absolute;
    top: 50%;
    left: 1%;
    right: 1%;
}
/*carousel*/

.carousel-inner {
    height: 103vh;
}

@media screen and (max-width:768px){
    .carousel-inner {
        height: 100vh;
    }
}

.carousel-box {
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.carousel {
    margin-top: 80px;
}

.carousel-box h3 {
    margin-top: -100px;
    font-size: 50px;
    text-align: left;
    color: black;
}

.carousel-box h5 {
    font-size: 30px;
    text-align: left;
    color: black;
}

.carousel-item video {
    filter: opacity(50%);
    width: 100%;
}

@media screen and (max-width: 768px) {
    .carousel {
        margin-top: 70px;
        height: 500px;
    }
    .carousel-box {
        top: 50%;
    }
    .carousel-box h1 {
        font-size: 40px;
    }
}

.row {
    padding: 50px;
    margin-right: 0px;
}

.col.title h1 {
    font-weight: 400;
}

.firstinfo {
    margin-top: 80px;
}

.titlediv {
    text-align: center;
    margin: 70px 0 50px;
}

.cards {
    text-align: center;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    width: 500px;
    background-color: #fff;
}

.modal-header,
modal-footer {
    padding: 10px;
}

.modal-title {
    margin: 0;
}

.modal-body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

#myBtn {
    display: none;
    position: absolute;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    cursor: pointer;
    width: 50px;
}

#myBtn:hover {
    background-color: #f66b0c;
}

#jobCard:hover {
    transition: all .3s ease-in-out;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
}

#searchinput {
    width: 400px;
    @media (min-width: 768px) {
        width: 50px;
    }
}

#myInput {
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
}

#Pcontent {
    margin-bottom: -10px;
    font-size: 100%;
}
.PostScroll{
    max-height: 100%;
    overflow-y: scroll;
    width: 100%;
    
}

html::-webkit-scrollbar {
    width: 10px;
  }

html::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 3px;
}

html::-webkit-scrollbar-thumb {
    background: #f66b0c;
    border-radius: 10px;
  }

html::-webkit-scrollbar-thumb:hover {
    background: rgb(100, 100, 100);
    border-radius: 10x;
  }

html::-webkit-scrollbar-thumb:active {
    background: rgb(68, 68, 68);
    border-radius: 10px;
  }